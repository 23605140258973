import React, { ReactNode } from "react";
import styled from "styled-components";

interface StyledContainerProps {
  columns: number;
}

const Container = styled.div<StyledContainerProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
`;

interface GridContainerProps {
  children: ReactNode;
  columns?: number;
  id?: string;
  className?: string;
}

const GridContainer: React.FC<GridContainerProps> = ({
  children,
  columns = 12,
  id,
  className,
}) => (
  <Container id={id} className={className} columns={columns}>
    {children}
  </Container>
);

export default GridContainer;
