import React from "react";

import { PaddingContainer } from "../components/Containers";
import { H1 } from "../components/Text";

const NotFoundPage: React.FC = () => (
  <PaddingContainer>
    <H1>404</H1>
  </PaddingContainer>
);

export default NotFoundPage;
