import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 0 1rem;
  margin: 2rem 0;
  display: grid;
  gap: 2rem 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 4rem 0;
    padding: 0 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    gap: 4rem 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.maxWidth}) {
    width: ${({ theme }) => theme.maxWidth};
  }

  @media screen and (min-width: calc(${({ theme }) => theme.maxWidth} + ${({
      theme,
    }) => theme.maxWidthOuterMargin})) {
    padding: 0;
  }
`;

interface PaddingContainerProps {
  className?: string;
}

const PaddingContainer: React.FC<PaddingContainerProps> = ({
  className,
  children,
}) => <Container className={className}>{children}</Container>;

export default PaddingContainer;
